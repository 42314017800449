import { dev } from '$app/environment';
import * as SentrySvelte from '@sentry/svelte';

// TODO: Do not send errors from local development
SentrySvelte.init({
  dsn: 'https://944cb09c9e6347d5929c83d6fa7d30ba@o4505034146054144.ingest.sentry.io/4505034150641664',
  integrations: [new SentrySvelte.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: dev ? 'DEV' : 'PROD',
});

SentrySvelte.setTag('svelteKit', 'browser');

// This will catch errors in load functions from +page.ts files
export const handleError = ({ error, event }) => {
  SentrySvelte.setTag('language', event.params?.language);
  SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } });

  if (error instanceof Error) {
    return {
      message: error.message,
    };
  } else {
    return undefined;
  }
};
